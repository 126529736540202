:root {
    --primary-color: #ff6f61; /* Soft coral */
    --secondary-color: #4caf50; /* Green */
    --text-color: #ffffff; /* White */
    --background-color: #f9f9f9; /* Light gray */
    --input-border-color: #b0bec5; /* Gray-blue */
    --hover-color: #e64a19; /* Dark coral */
}

.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: var(--background-color);
    padding: 0 20px;
    position: relative;
}

.auth-banner {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary-color);
    color: var(--text-color);
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    font-weight: 500;
    opacity: 1;
    animation: slideIn 1s forwards;
}

@keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateX(-50%) translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
}

.auth-card {
    background-color: #f9f9f9;
    border-radius: 15px;
    padding: 40px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

.auth-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.15);
}

.auth-header {
    text-align: center;
    margin-bottom: 20px;
}

.auth-title {
    font-size: 1.8rem;
    margin-bottom: 5px;
}

.auth-toggle {
    font-size: 0.9rem;
    color: #666;
}

.toggle-link {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 500;
    transition: color 0.3s;
}

.toggle-link:hover {
    color: var(--hover-color);
    text-decoration: underline;
}

.auth-form {
    display: flex;
    flex-direction: column;
}

.input-group {
    margin-bottom: 20px;
}

.auth-input {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid var(--input-border-color);
    font-size: 1rem;
    transition: border 0.3s ease-in-out;
}

.auth-input:focus {
    border: 1px solid var(--primary-color);
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}

.auth-button {
    width: 100%;
    background-color: var(--primary-color);
    color: #ffffff;
    padding: 12px;
    font-size: 1rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.2s;
}

.auth-button.primary:hover {
    background-color: var(--hover-color);
    transform: scale(1.02);
}

.auth-button.secondary {
    background-color: #6c757d;
    margin-top: 10px;
}

.auth-button.secondary:hover {
    background-color: #5a6268;
}

.animated-banner {
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
